<ng-container *ngIf="showIt">
	<h2 mat-dialog-title class="m-0 mb-8">{{ "dialogo.ayuda.header" | translate }}</h2>

	<mat-dialog-content class="mat-typography" style="width: 60vh">
		<ng-container *ngIf="step == 0">
			<div fxLayout="row" fxLayoutAlign="start start">
				<h4>{{ "dialogo.ayuda.sub" | translate }}</h4>
			</div>

			<div fxLayout="row" fxLayoutAlign="center start">
				<button mat-raised-button class="btn-feedbalia boton"
					(click)="selectOne('EDT', 'Incidencia con la EDT')">
					{{ "dialogo.ayuda.edt" | translate }}
				</button>
			</div>
			<div fxLayout="row" fxLayoutAlign="center start">
				<button mat-raised-button class="btn-feedbalia boton"
					(click)="selectOne('Vueltas D', 'Incidencia con Vueltas D')">
					{{ "dialogo.ayuda.vd" | translate }}
				</button>
			</div>
			<div fxLayout="row" fxLayoutAlign="center start">
				<button mat-raised-button class="btn-feedbalia boton" (click)="selectOtraIncidencia()">{{
					"dialogo.ayuda.otro" | translate }}</button>
			</div>
		</ng-container>

		<ng-container *ngIf="step == 1.1">
			<div fxLayout="row" fxLayoutAlign="start start">
				<h4>{{ "dialogo.ayuda.lamentamosall" | translate }}</h4>
			</div>
			<form [formGroup]="formulario">
				<!-- Fila 1-->
				<h5 style="margin: 0px 0px 0px 0px !important; padding-left: 8px">{{ "dialogo.ayuda.describa" |
					translate }}</h5>

				<div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 2px">
					<div fxLayout="column" fxFlex="100" class="p-8" style="padding-top: 0px !important">
						<mat-form-field subscriptSizing="dynamic" style="margin-top: 0px !important">
							<input matInput formControlName="asunto" />
						</mat-form-field>
						<mat-error *ngIf="formulario.get('asunto').hasError('thisValueTooLong')">
							{{ "dialogo.ayuda.80" | translate }}
						</mat-error>
					</div>
				</div>

				<!-- Fila 2 -->
				<h5 style="margin: 0px 0px 0px 0px !important; padding-left: 8px">{{ "dialogo.ayuda.detallesuceso" |
					translate }}</h5>

				<div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 4px; height: auto">
					<div fxLayout="column" fxFlex="100" class="p-8"
						style="width: 100%; height: auto; padding-top: 0px !important">
						<mat-form-field subscriptSizing="dynamic" appearance="fill" style="width: 100%; height: auto">
							<textarea matInput formControlName="cuerpo" rows="5"
								style="white-space: pre-line; height: auto"></textarea>
						</mat-form-field>
						<mat-error *ngIf="formulario.get('cuerpo').hasError('thisValueTooLong')">
							{{ "dialogo.ayuda.1024" | translate }}
						</mat-error>
					</div>
				</div>
			</form>

			<div fxLayout="row" fxLayoutAlign="left left" style="margin-top: 2px">
				<div fxLayout="column" fxFlex="1 1 0" class="p-8">
					<!--button mat-raised-button class="btn-back" (click)="backStepForm(0)">{{ 'dialogo.ayuda.ant' | translate }}</button-->
				</div>
				<div fxLayout="column" fxFlex="1 1 0" class="p-8">
					<button mat-raised-button class="btn-feedbalia" (click)="step = 2.1">{{ "dialogo.ayuda.sig" |
						translate }}</button>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="step == 2.1">
			<div fxLayout="row" fxLayoutAlign="start start">
				<h4>{{ "dialogo.ayuda.adjuntar" | translate }}</h4>
			</div>
			<!--form [formGroup]="uploadForm" fxLayoutAlign="center center" (ngSubmit)="onSubmit()" style="width: 100% !important;">
                  <div class="form-row" style="width: 100% !important;">
                    <input style="display: none" #fileInput type="file" (change)="onFileSelected(fileInput.files)" required>
                    <button mat-raised-button color="primary" (click)="fileInput.click()">{{ 'dialogo.ayuda.selarchivo' | translate }}</button>
                    <span style="margin-left: 6px;">{{ selectedFileName }}</span>
                  </div>
                </form-->
			<div fxLayout="row" fxLayoutAlign="left left" style="margin-top: 20px" *ngIf="!captura">
				<div fxLayout="column" fxFlex="1 1 0" class="p-8"></div>
				<div fxLayout="column" fxFlex="1 1 0" class="p-8">
					<button mat-raised-button color="primary" (click)="captureScreen()">{{ "dialogo.ayuda.selarchivo" |
						translate }}</button>
				</div>
				<div fxLayout="column" fxFlex="1 1 0" class="p-8"></div>
			</div>
			<div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px; max-height: 200px"
				*ngIf="captura">
				<img src="{{ captura }}" width="auto" height="auto" style="text-align: center" />
			</div>

			<div fxLayout="row" fxLayoutAlign="left left" style="margin-top: 20px">
				<div fxLayout="column" fxFlex="1 1 0" class="p-8">
					<button mat-raised-button class="btn-back" (click)="backStepArchivo(1.1)">{{ "dialogo.ayuda.ant" |
						translate }}</button>
				</div>
				<div fxLayout="column" fxFlex="1 1 0" class="p-8">
					<button mat-raised-button class="btn-feedbalia"
						[disabled]="!formulario.valid || enviando || !captura" (click)="sendIt()">
						{{ enviando ? "Enviando..." : "Enviar incidencia" }}
					</button>
				</div>
			</div>
		</ng-container>
	</mat-dialog-content>
</ng-container>